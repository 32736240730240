.document-viewer-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.document-viewer-page-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  object-fit: contain;
}

