.section-subheading {
  color: var(--UIcolour1);
  font-size: 18px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 30px;
}

.sub-section-heading {
  color: var(--UIcolour1);
  font-family: var(--UIfontType1SemiBold);
  font-size: 18px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 30px;
}

.sub-section-text {
  color: var(--UIcolour2);
  font-family: var(--UIfontType1);
  font-size: 16px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 30px;
}

.sub-section-text li {
  color: var(--UIcolour2);
  font-family: var(--UIfontType1);
  font-size: 16px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 30px;
}

.top-section-wrapper {
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 451px) {
  .top-section-wrapper {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .top-section-wrapper {
    margin-right: 0px;
    flex-wrap: wrap;
  }
}

.mid-section-wrapper {
  margin-left: 0px;
}

@media only screen and (min-width: 451px) {
  .mid-section-wrapper {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .mid-section-wrapper {
    margin-right: 0px;
  }
}

.top-section-detail-snippet {
  background: white;
}

.top-section-callout-banner {
  background: var(--UIColour0);
  height: 175px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 0px;
  margin-bottom: 30px;
  border: 1px solid var(--UIcolour2);
  border-radius: var(--UIborderRadius);
  display: flex;
}

@media only screen and (min-width: 451px) {
  .top-section-callout-banner {
    min-width: 175px;
    margin-left: auto;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .top-section-callout-banner {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.top-section-callout-banner-inner {
  margin-top: auto;
  margin-bottom: auto;
}

@media only screen and (min-width: 451px) {
  .top-section-callout-banner-inner {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .top-section-callout-banner-inner {
    margin-left: auto;
    margin-right: auto;
  }
}

.top-section-callout-banner-header {
  color: var(--UIcolour2);
  font-size: 18px;
  text-align: center;
}

.top-section-callout-banner-value {
  color: var(--UIcolour2);
  font-family: var(--UIfontType1SemiBold);
  font-size: 24px;
  margin: 15px;
  text-align: center;
}

.url-callout-banner {
  background: white;
  border: 1px solid var(--brandColour1);
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 30px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 451px) {
  .url-callout-banner {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 450px) {
  .url-callout-banner {
    flex-wrap: wrap;
  }
}

.url-callout-banner-left {
  background: var(--brandColour1);
  height: auto;
  margin: 0px;
  display: flex;
}

@media only screen and (min-width: 451px) {
  .url-callout-banner-left {
    width: auto;
  }
}

@media only screen and (max-width: 450px) {
  .url-callout-banner-left {
    width: 100%;
  }
}

.url-callout-banner-header {
  color: var(--UIcolour0);
  font-size: 20px;
  margin: 15px;
}

.url-callout-banner-header {
  width: 100%;
  text-align: center;
  color: var(--brandColour0);
  font-family: var(--UIfontType1SemiBold);
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.url-callout-banner-value {
  color: var(--brandColour1);
  font-family: var(--UIfontType1SemiBold);
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.stats-section-callout-banner-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 450px) {
  .stats-section-callout-banner-wrapper {
    flex-wrap: wrap;
  }
}

.stats-section-callout-banner {
  background: white;
  border: 1px solid var(--UIcolour2);
  height: auto;
  margin-top: 15px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
}

@media only screen and (min-width: 451px) {
  .stats-section-callout-banner {
    width: 33.3%;
  }
}

@media only screen and (max-width: 450px) {
  .stats-section-callout-banner {
    width: 100%;
  }
}

.stats-section-callout-banner-spacer {
  background: white;
  border: none;
  width: 15px;
  height: auto;
  margin: 0;
}

.stats-section-callout-banner-inner {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.stats-section-callout-banner-header {
  color: var(--UIcolour2);
  font-size: 18px;
  text-align: center;
}

.stats-section-callout-banner-value {
  color: var(--UIcolour2);
  font-family: var(--UIfontType1SemiBold);
  font-size: 24px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px;
  text-align: center;
}

.ts-and-cs-text {
  color: var(--UIcolour2);
  font-family: var(--UIfontType1);
  font-size: 13px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.ts-and-cs-text ol {
  padding-left: 0;
  list-style-position: inside;
}

.ts-and-cs-text li {
  margin-bottom: 8px;
}

.referral-link-button {
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  border-radius: var(--UIborderRadius);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  flex-direction: row;
  justify-content: center;
  background: var(--brandColour1);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
}

.referral-link-button:hover,
.referral-link-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
  cursor: pointer;
}

.referral-link-button-nohover {
  margin-top: 15px;
  margin-right: 0px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--brandColour1);
  border: 1px solid var(--brandColour1);
  border-radius: 0 0 var(--UIborderRadius) var(--UIborderRadius);
}

.referral-label-and-link-wrapper {
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.referral-link-label {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  color: white;
  text-align: center;
  text-decoration: none;
}

.referral-link-box-icon {
  height: 16px;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  fill: white;
}
