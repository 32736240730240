.popup-modal-open-button-round {
  background-color: white;
  height: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid var(--UIcolour4);
  border-radius: 50px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popup-modal-open-button-round:hover {
  border: 1px solid var(--UIcolour3);
}

.popup-modal-open-button-round-text {
  margin-top: auto;
  margin-bottom: auto;
  margin: auto;
  padding: 0;
  font-size: 10px;
  color: var(--UIcolour2);
  line-height: 100%;
}

.popup-modal-open-button-round-text:hover {
  color: var(--UIcolour1);
}

.popup-modal-box {
  margin: auto;
  width: 100%;
  max-width: 555px;
  background-color: white;
  border: 1px solid var(--UIcolour4);
  border-radius: var(--UIborderRadius);
  filter: drop-shadow(0rem 0rem 0.8rem var(--UIcolour7));
  animation: globalFadeIn ease var(--UItransitionTime);
  -webkit-animation: globalFadeIn ease var(--UItransitionTime);
  -moz-animation: globalFadeIn ease var(--UItransitionTime);
  -o-animation: globalFadeIn ease var(--UItransitionTime);
  -ms-animation: globalFadeIn ease var(--UItransitionTime);
}

.popup-modal-close-button-wrapper {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.popup-modal-close-button {
  background-color: var(--UIcolour0);
  border-radius: 50%;
  border: 1px solid var(--theme-color-primary);
  height: 30px;
  width: 30px;
  margin-top: 0px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 0px;
  cursor: pointer;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.popup-modal-close-button-icon-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.popup-modal-close-button-icon {
  background-color: transparent;
  height: 16px;
  width: 16px;
  margin: auto;
  fill: var(--theme-color-primary);
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.popup-modal-content-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: -15px;
  margin-right: 30px;
  margin-left: 30px;
}
