.conversation-list-item-read {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  padding-bottom: 7px;
  padding: 15px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour0);
  border: 1px solid var(--UIcolour6);
  transition: background var(--UItransitionTime);
}

.conversation-list-item-unread {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  padding-bottom: 7px;
  padding: 15px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour11);
  transition: background var(--UItransitionTime);
}

@media only screen and (max-width: 660px) {
  .conversation-list-item-read {
    flex-direction: column-reverse;
  }
  .conversation-list-item-unread {
    flex-direction: column-reverse;
  }
}

.conversation-list-item-main-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.conversation-list-item-status-container {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}

.conversation-list-item-heading-container {
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  justify-content: space-between;
}

.conversation-list-item-status-container {
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  justify-content: space-between;
}

.conversation-list-item-content-container {
  display: flex;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.conversation-list-item-body-container {
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  justify-content: space-between;
}

.conversation-list-item-left-container {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}

.conversation-list-item-right-container {
  display: flex;
  margin-right: 5px;
  flex-direction: row;
}

.conversation-list-item-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour1);
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 8px;
  text-align: left;
}

.conversation-list-item-date {
  font-family: var(--UIfontType1);
  font-size: 14px;
  color: var(--UIcolour2);
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 8px;
  text-align: right;
}

.conversation-list-item-subheading {
  font-family: var(--UIfontType1);
  font-size: 14px;
  color: var(--UIcolour2);
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 8px;
  text-align: left;
}

.conversation-list-item-select {
  font-size: 11px;
  height: 25px;
  max-width: 90px;
  color: var(--UIcolour1);
  border: 1px solid;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 0.1rem;
  font-family: var(--UIfontType1);
  margin-bottom: auto;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
}

.conversation-list-item-select-spacer {
  height: 25px;
  width: 200px;
  background: pink;
}

@media only screen and (max-width: 660px) {
  .conversation-list-item-subheading {
    font-size: 14px;
  }
}

.conversation-list-item-main-image-wrapper-loading {
  border: none;
  margin-top: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.conversation-list-item-main-image-loading {
  display: block;
  margin: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  background: #777;
  border: none;
}

.conversation-list-item-main-image-loading-animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  border: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.conversation-list-number-container {
  display: flex;
  justify-content: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.conversation-list-number-badge {
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  background-color: var(--brandColour3);
  color: var(--UIcolour0);
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}
