.conversation-response-file-uploader-inner-container-left {
  margin: 0;
  margin-right: 0px;
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 30px;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
}

.conversation-response-file-uploader-inner-container-left-selected {
  margin: 0;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.conversation-response-imageuploader-image-input {
  display: none;
}

.conversation-response-imageuploader-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  margin-bottom: 0px;
  object-fit: cover;
}

.conversation-response-file-upload {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: auto;
  margin-right: 0px;
  padding: auto;
  background-color: var(--UIcolour6);

  width: 100%;
  height: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.conversation-response-file-upload-selected {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  padding: auto;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  border-color: var(--UIcolour3);
  width: 100%;
  height: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.conversation-response-file-icon-container {
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.conversation-response-imageuploader-custom-file-upload-icon {
  margin: auto;
  height: 28px;
  width: 28px;
  fill: var(--UIcolour2);
}
