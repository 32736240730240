.button-with-loading {
  font-family: "SF Pro Text", Sans-Serif;
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 160px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: middle;
  background-color: var(--brandColour1);
  border: 1px solid;
  border-color: var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding: 12px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.button-with-loading:hover,
.button-with-loading:focus {
  background-color: var(--brandColour1Dulled);
  border-color: var(--brandColour1Dulled);
  outline: 0;
}

.button-with-loading:disabled {
  opacity: 0.6;
}

.button-with-loading-text {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  color: var(--UIcolour0);
  font-size: 18px;
  margin: auto;
}

.button-with-loading-text-loading {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--UIcolour0);
  font-size: 18px;
  margin: auto;
  padding: auto;
}

.button-with-loading-delete {
  font-family: "SF Pro Text", Sans-Serif;
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 160px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: middle;
  background-color: var(--UIcolour5);
  border: none;
  border-radius: var(--UIborderRadius);
  padding: 12px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.button-with-loading-delete:hover,
.button-with-loading-delete:focus {
  background-color: var(--UIcolour5);
  outline: 0;
}

.button-with-loading-delete:disabled {
  opacity: 0.6;
}

.button-with-loading-text-delete {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  color: var(--UIcolour0);
  font-size: 18px;
  margin: auto;
}

.button-with-loading-text-loading-delete {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--UIcolour0);
  font-size: 18px;
  margin: auto;
  padding: auto;
}


.button-with-loading-inverted {
  font-family: "SF Pro Text", Sans-Serif;
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 160px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: middle;
  background-color: var(--UIcolour0);
  border: 1px solid;
  border-color: var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding: 12px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.button-with-loading-inverted:hover,
.button-with-loading-inverted:focus {
  color: var(--brandColour1Dulled);
  border-color: var(--brandColour1Dulled);
  outline: 0;
}

.button-with-loading-inverted:disabled {
  opacity: 0.6;
}

.button-with-loading-text-inverted {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  color: var(--brandColour1);
  font-size: 18px;
  margin: auto;
}

.button-with-loading-text-loading-inverted {
  font-family: "SF Pro Text", Sans-Serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--UIcolour0);
  font-size: 18px;
  margin: auto;
  padding: auto;
}