.headerbar-outer {
  background-color: var(--UIcolour0);
  height: 62px;
  border-bottom: 1px solid var(--UIcolour3);
  z-index: 999;
  width: 100%;
  margin-bottom: 0px;
  transition: opacity 0.05s linear;
}

.headerbar-outer-fixed {
  background-color: var(--UIcolour0);
  height: 62px;
  border-bottom: 1px solid var(--UIcolour3);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  margin-bottom: 0px;
}

.headerbar {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  margin: auto;
}

.headerbar a {
  width: 140px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.header-logo-container {
  width: 140px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.header-logo {
  width: 140px;
  height: 40px;
  margin: auto;
  cursor: pointer;
}

.header-button-and-avatar-container {
  margin: 0;
  display: flex;
}

.header-profile-image {
  width: 35px;
  height: 35px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;

  outline: 0;
  cursor: pointer;
  object-fit: cover;
}

.hamburger-menu {
  width: 22px;
  height: 22px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  outline: 0;
  cursor: pointer;
}

.header-chat-icon-container {
  margin-left: 15px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  outline: 0;
  cursor: pointer;
  height: 32px;
  width: 32px;
  padding: auto;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.header-chat-icon {
  background-color: transparent;
  height: 32px;
  width: 32px;
  margin: auto;
  fill: #d0d0d0;
}

.header-chat-icon-active {
  background-color: transparent;
  height: 22px;
  width: 22px;
  margin-top: auto;
  fill: var(--theme-color-primary);
}

.header-chat-icon-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  background-color: var(--UIcolour5);
  color: var(--UIcolour0);
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.inline-static-link-header-container {
  margin-top: 40px;
  margin-bottom: -70px;
  margin-left: auto;
  margin-right: auto;
  padding: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inline-static-link-header-grey {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour2);
  background: transparent;
  font-size: 13px;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}
