.infoitem-subcontent-module-container {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.infoitem-text-label-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.infoitem-texteditor-label {
  font-size: 16px;
  line-height: 130%;
  color: var(--UIcolour1);
  margin-top: auto;
  margin-bottom: 0;
}

.infoitem-subcontent-buttons-container {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.infoitem-subcontent-button {
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  color: var(--brandColour1);
  transition: color var(--UItransitionTime);
  background-color: var(--UIcolour0);
  transition: background-color var(--UItransitionTime);
  border: 1px solid var(--brandColour1);
  border-radius: 15px;
  cursor: pointer;
}

.infoitem-subcontent-button:hover,
.infoitem-subcontent-button:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.infoitem-text-delete-button {
  height: 21px;
  width: 35px;
  border: none;
  background: white;
  cursor: pointer;
}
.infoitem-text-delete-icon {
  margin: auto;
  height: 20px;
  width: 20px;
  fill: var(--UIcolour2);
}

.infoitem-text-delete-button:hover .infoitem-text-delete-icon {
  fill: var(--brandColour1Dulled);
}

.infoitem-text-delete-button:focus .infoitem-text-delete-icon {
  fill: var(--brandColour1Dulled);
}
