.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
  border-color: var(--UIcolour7);
  border-radius: var(--UIborderRadius);
}

.table__heading {
  font-size: 14px;
  color: var(--UIcolour1);
  border: 1px solid;
  border-color: var(--UIcolour7);
  border-radius: var(--UIborderRadius);
  padding: 0.5rem;
  text-align: center;
}

.table__cell {
  color: var(--UIcolour2);
  font-size: 16px;
  padding: 0.5rem;
  white-space: wrap;
}

.table__row:nth-child(even) {
  background-color: var(--UIcolour7);
}

@media only screen and (min-width: 761px) {
  .table td {
    text-align: center;
    color: var(--UIcolour2);
    font-size: 16px;
    padding: 0.5rem;
    white-space: nowrap;
  }

  .table td:first-child {
    text-align: left;
    color: var(--UIcolour2);
    font-size: 16px;
    padding: 0.5rem;
    white-space: nowrap;
    min-width: 100px;
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .table,
  .table * {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .table__head {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__cell {
    display: block;
    border: none;
    border-bottom: 1px solid var(--UIcolour7);
    font-size: 15px;
    position: relative;
    padding-left: 60%;
    text-align: left;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table__cell::before {
    content: attr(data-label);
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 50%;
    left: 0.5rem;
    right: 0.5rem;
    transform: translateY(-50%);
    width: 50%;
    padding-right: 1rem;
    color: var(--UIcolour1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: normal;
  }
}

.table-select {
  font-size: 14px;
  color: var(--UIcolour1);
  border: 1px solid;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 0.5rem;
  font-family: var(--UIfontType1);
  margin-bottom: 20px;
  margin-right: 20px;
}
