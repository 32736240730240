.back-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: start;
  cursor: pointer;
  margin-left: 0;
}

.back-button-icon-and-text-container {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
}

.back-button-previous-section {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-decoration: underline;
  cursor: pointer;
  transition: color var(--UItransitionTime);
}

.back-button-previous-section:hover,
.back-button-previous-section:active {
  color: var(--UIcolour1);
}

.back-button-chevron-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
}

.back-button-chevron-icon-wrapper-left {
  display: flex;
  justify-content: center;
  margin-right: 5px;
  margin-left: 0px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
}

.back-button-chevron-icon {
  height: 9.2px;
  width: 9.2px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour2);
}

.breadcrumb-current-section {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.back-button-link {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}
