.file-list-item {
  margin: auto;
}

.file-list-item-checkmark-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 8px;
}

.file-list-item-checkmark-icon-container-checked {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.file-list-item-checkmark-icon-checked {
  height: 12px;
  width: 12px;
  margin: 4px;
  fill: var(--UIcolour0);
}

.file-list-item-text-container {
  margin: auto;
  margin-left: 0;
  margin-bottom: 0;
}

.file-list-item-text {
  margin-left: 0;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
  text-align: left;
}
