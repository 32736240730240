.breadcrumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  height: auto;
  margin: auto;
  margin-left: 0;
}

.breadcrumb-icon-and-text-container {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
}

.breadcrumb-previous-section {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  transition: color var(--UItransitionTime);
}

.breadcrumb-previous-section:hover,
.breadcrumb-previous-section:active {
  color: var(--UIcolour1);
}

.breadcrumb-chevron-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
}

.breadcrumb-chevron-icon-wrapper-left {
  display: flex;
  justify-content: center;
  margin-right: 3px;
  margin-left: 0px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
}

.breadcrumb-chevron-icon {
  height: 9.2px;
  width: 9.2px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour2);
}

.breadcrumb-current-section {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.breadcrumb-link {
  font-family: var(--UIfontType1);
  color: var(--UIcolour2);
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100vw * 0.22) !important;
}
