.infoitem-checkmark-outer-wrapper {
  margin-left: 0;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: auto;
}

.infoitem-checkmark-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 0px;
}

.infoitem-checkmark-icon-wrapper-checked {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.infoitem-checkmark-icon-wrapper-semi-checked {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--UIcolour2);
}

.infoitem-checkmark-icon-checked {
  height: 12px;
  width: 12px;
  margin: 4px;
  fill: white;
}

.infoitem-checkmark-icon-wrapper-unchecked-required {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--UIcolour5);
}

.infoitem-checkmark-icon-wrapper-unchecked {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--UIcolour2);
}

.infoitem-checkmark-icon-unchecked {
  height: 10px;
  width: 10px;
  margin: 5px;
  fill: white;
}

.infoitem-checkmark-icon-wrapper-unchecked-no-margin {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: white;
}

.infoitem-checkmark-icon-unchecked-no-margin {
  height: 20px;
  width: 20px;
  fill: var(--brandColour1);
}

.infoitem-checkmark-icon-wrapper-na {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.infoitem-checkmark-icon-na {
  height: 10px;
  width: 10px;
  margin: 5px;
  fill: white;
}

.infoitem-checkmark-description-wrapper {
  margin: auto;
  margin-left: 0;
  margin-bottom: 0;
}

.infoitem-checkmark-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0;
}

.infoitem-checkmark-description {
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.infoitem-multi-checkmark-description {
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-checkmark-description-ul {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: auto;
  padding: 0;
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
}

.infoitem-checkmark-edit-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color var(--UItransitionTime);
  text-decoration: underline;
}

.infoitem-checkmark-edit-button:hover {
  text-decoration: underline;
}

.infoitem-checkmark-edit-button:focus {
  outline: 0;
}

@media only screen and (max-width: 959px) {
  .tooltip-show-hide {
    display: none;
  }
}

@media only screen and (min-width: 960px) {
  .tooltip-show-hide {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
}

.tooltip-outer-wrapper {
  margin-left: 0;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: auto;

  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: var(--UIborderRadius);
}

.tooltip-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 0px;
}

.tooltip-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.tooltip-description {
  font-size: 14px;
  color: var(--UIcolour8);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
