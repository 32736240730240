.logout-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  width: 75px;
  height: 35px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding: auto;
  color: var(--brandColour1);
  font-size: 14px;
  cursor: pointer;
  transition: color var(--UItransitionTime);
  transition: background-color var(--UItransitionTime);
}

.logout-button:hover,
.logout-button:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.logout-button-spinner {
  margin-left: 22px;
  margin-top: 5px;
}
