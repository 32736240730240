.form-phone-input-container {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  outline: none !important;
  appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 0px;
}

.form-phone-input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  outline: none !important;
  appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 45px !important;
  font-family: var(--UIfontType1) !important;
  font-size: 16px !important;
  color: var(--UIcolour2) !important;
  transition: border-color var(--UItransitionTime);
}

.form-phone-input:focus {
  border-color: var(--brandColour1) !important;
  box-shadow: 0 0 0 0 !important;
  /* box-shadow: 0 0 0 0.2rem var(--theme-color-ui-light-banner) !important; */
}

.form-phone-input::placeholder {
  color: var(--UIcolour3);
}

.form-phone-input-dropdown-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  outline: none !important;
  appearance: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

.form-phone-input-dropdown-button:focus {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box !important;
  outline: none !important;
  appearance: none !important;
  box-shadow: 0 0 0 0 !important;
}

.selected-flag:before {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: none !important;
  appearance: none !important;
  box-shadow: 0 0 0 0 !important;
}

.form-phone-input-dropdown-button:before {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box !important;
  outline: none !important;
  appearance: none !important;
  box-shadow: 0 0 0 0 !important;
}

.form-phone-input-dropdown {
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -moz-appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  outline: none !important;
  appearance: none !important;
  margin-left: 0;
  box-shadow: 0 0 0 0 !important;
  font-family: var(--UIfontType1) !important;
  font-size: 16px !important;
  color: var(--UIcolour2) !important;
  border-radius: var(--UIborderRadius) !important;
}
