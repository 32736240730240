.chat {
  width: 100%;
  max-width: 500px;
  height: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0 auto !important;
  margin-right: 0 auto !important;
}

.chat-head {
  border-left: 1px solid var(--UIcolour6);
  border-right: 1px solid var(--UIcolour6);
  height: 20px;
  padding: 10px;
  font-family: var(--UIfontType1SemiBold);
  color: var(--UIcolour2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.messages {
  height: calc(100svh - 198px);
  border: 1px solid var(--UIcolour6);
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--UIcolour0);
  overflow-y: scroll;
  padding: 15px 10px;
  margin-bottom: 0;
}

.msg {
  border: 1px solid #ddd;
  padding: 7px 15px;
  border-radius: 20px;
  font-size: 13px;
  background: #dfe6e9;
  margin-bottom: 20px;
  width: 80%;
  float: left;
}

.msg.dark {
  float: right;
  background: #00cec9;
}

.chat-footer {
  display: flex;
  height: 60px;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
}

.sender-input {
  padding: 10px;
  width: 290px;
  border: 0;
  margin-right: 10px;
}

.sender-input:focus {
  outline: none;
}

.chat-footer img {
  cursor: pointer;
}
