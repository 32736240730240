.setup-flow-headerbar-outer {
  height: 62px;
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  margin-bottom: 0px;
}

.setup-flow-headerbar {
  background-color: #ffffff;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: start;
  max-width: 1080px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.setup-flow-header-logo {
  width: 140px;
  height: 40px;
  margin-left: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.create-account-canvas {
  margin-top: var(--headerHeight);
  padding: 15px;
}

.create-account-canvas-inner {
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--globalPageWidth);
}

.create-account-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  max-width: var(--globalContentWidth);
}

.create-account-inner-container-left {
  padding: 15px;
}

.create-account-inner-container-right {
  padding: 15px;
}

.setupflow-heading {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-family: var(--UIfontType1SemiBold);
  font-size: 32px;
  color: #333333;
  line-height: 130%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.setupflow-subheading {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-family: var(--UIfontType1);
  font-size: 16px;
  line-height: 130%;
  color: #6a6a6a;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cta-button-subtext {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.cta-button-subtext a,
cta-button-subtext a:visited {
  color: inherit;
}

.welcome-banner-container {
  margin-bottom: 30px;
}

.welcome-banner {
  height: auto;
  width: 100%;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  border-left: 5px solid var(--brandColour3);
  background: var(--UIcolour6);
  transition: filter var(--UItransitionTime);
}

.welcome-banner-header {
  margin-left: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 18px;
  color: var(--UIcolour1);
}

.welcome-banner-point-container {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.welcome-banner-point-text {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
}
