.nav-headerbar-outer {
  background-color: var(--UIcolour0);
  height: 62px;
  border: none;
  /* position: fixed;
  top: 62px;
  */
  width: 100%;
  z-index: 998;
  margin-bottom: 0px;
  padding: 15px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--UIcolour3);
}

.nav-headerbar-outer-large {
  background-color: var(--UIcolour0);
  height: 62px;
  border-top: 0px solid var(--UIcolour0);
  width: 100%;
  z-index: 998;
  position: sticky;
  top: -1px;
  left: 0;
  right: 0;
  border-bottom: 0px solid var(--UIcolour0);
  transition: 0.3s ease;
}

.nav-headerbar-outer-small {
  background-color: var(--UIcolour0);
  height: 62px;
  border-top: 0px solid var(--UIcolour0);
  width: 100%;
  z-index: 998;
  position: sticky;
  top: -1px;
  border-bottom: 1px solid var(--UIcolour3);
  transition: 0.3s ease;
}

.nav-headerbar-inner-large {
  background-color: var(--UIcolour0);
  height: 62px;
  width: 100%;
  z-index: 999;
  margin: auto;
  max-width: 990px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: middle;
}

.nav-headerbar-inner-small {
  background-color: var(--UIcolour0);
  height: 62px;
  width: 100%;
  margin: auto;
  z-index: 999;
  padding: auto;
  max-width: 990px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .nav-headerbar-outer-large {
    height: 50px;
  }
  .nav-headerbar-outer-small {
    height: 50px;
  }
  .nav-headerbar-inner-large {
    height: 50px;
  }
  .nav-headerbar-inner-small {
    height: 50px;
  }
}

.nav-headerbar-inner-left {
  height: auto;
  margin-left: 0px;
  margin-right: 10px;
  padding-left: 10px;
  width: 33.3%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.nav-headerbar-inner-right {
  height: auto;
  margin-left: 10px;
  margin-right: 0px;
  padding-right: 10px;
  width: 33.3%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.nav-headerbar-inner {
  max-width: 990px;
}

.nav-headerbar-inner-top {
  background-color: var(--UIcolour0);
  height: auto;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 990px;
}

.nav-headerbar-banner {
  background-color: var(--UIcolour9);
  height: auto;
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--UIcolour0);
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.h1-header-large {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-weight: 600;
  font-size: 30px;
  color: var(--UIcolour1);
  transition: 0.15s ease;
}

@media only screen and (max-width: 600px) {
  .h1-header-large {
    letter-spacing: -0.5px;
    font-size: 20px;
  }
}

.h1-header-hidden {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  font-family: var(--UIfontType1);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: var(--UIcolour0);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 33.3%;
}

@media only screen and (max-width: 600px) {
  .h1-header-hidden {
    letter-spacing: -0.5px;
    font-size: 14px;
  }
}

.h1-header-small {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  font-family: var(--UIfontType1);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: var(--UIcolour1);
  transition: 0.1s ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 33.3%;
}

@media only screen and (max-width: 600px) {
  .h1-header-small {
    letter-spacing: -0.5px;
    font-size: 14px;
  }
}
