.menu-item-image-outer-wrapper {
  display: flex;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: auto;
  border: none;
}

.menu-item-image-inner-wrapper {
  margin: auto;
}

.menu-item-image {
  object-fit: cover;
  height: 70px;
  width: 105px;
  border: none;
  border-radius: var(--UIborderRadius);
}

.menu-item-image-text-container {
  height: 70px;
  width: 105px;
  border: none;
  border-radius: var(--UIborderRadius);
  display: flex;
  background: var(--UIcolour4);
}

.menu-item-image-text {
  margin: auto;
  text-align: center;
  font-size: 12px;
  color: var(--UIcolour2);
}

.menu-item-image-icon-outer-wrapper {
  display: flex;
  margin-right: 15px;
  background: var(--theme-color-secondary);
  height: 70px;
  width: 105px;
  min-width: 105px;
  border: none;
  border-radius: var(--UIborderRadius);
  align-content: center;
  align-items: center;
}

.menu-item-image-icon-inner-wrapper {
  margin: auto;
  height: 50px;
  width: auto;
}

.menu-item-image-icon {
  object-fit: contain;
  height: 50px;
  width: auto;
  margin: 0;
  border: none;
  background: var(--theme-color-secondary);
  border-radius: var(--UIborderRadius);
}

.menu-item-image-outer-wrapper-loading {
  border: none;
  border-radius: 4px;
  padding: 0px;
  width: 105px;
  margin-right: 15px;
}

.menu-item-image-inner-wrapper-loading {
  width: 0px;
  border: none;
  margin: 0;
  border-radius: 4px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.menu-item-image-loading {
  height: 70px;
  width: 105px;
  background: #777;
  border: none;
  margin: auto;
  border-radius: 4px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}


.menu-item-image-loading-animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  border: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
