.create-new-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.create-new-button:hover,
.create-new-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.create-new-button:disabled {
  opacity: 0.6;
}

.create-new-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.create-new-button-inverted:hover,
.create-new-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.create-new-button-inverted:disabled {
  opacity: 0.6;
}
