.canvas-with-header-and-footer {
  margin-top: var(--headerHeight);
  padding: 15px;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.canvas-with-header-navheader-and-footer {
  margin-top: 0px;
  padding: 15px;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.canvas-with-header-and-footer-inner-container {
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--globalPageWidth);
}

.section-break-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

/* @media only screen and (min-width: 650px) {
  .section-break-wrapper {
    margin-top: 45px;
  }
}
*/

.section-break {
  border-top: 1px solid #cccccc;
  height: auto;
  max-width: 960px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}
