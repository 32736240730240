.colour-picker-preview-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 0px;
}

.colour-picker-preview {
  height: 75px;
  width: 75px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: var(--UIborderRadius);
}

.colour-picker-preview-text {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
}

.colour-picker-outer {
  margin-top: 0px;
  width: 150px;
  margin-bottom: 15px;
  border-radius: var(--UIborderRadius);
}

.react-colorful {
  margin-top: 8px;
  margin-bottom: 8px;
  height: 150px;
  width: 150px;
  border-radius: var(--UIborderRadius);
}

.colour-picker-input-and-hashtag-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.colour-picker-text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 122px;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 40px;
  font-size: 16px;
  transition: border-color var(--UItransitionTime);
}

.colour-picker-text-input:focus {
  border-color: var(--brandColour1);
}

.colour-picker-text-input::placeholder {
  color: var(--UIcolour3);
}
