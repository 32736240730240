.message-submit-mini-button-round {
  background-color: var(--brandColour1);
  font-size: 14px;
  height: auto;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: none;
  border-radius: 50px;
  margin-left: 10px;
  margin-right: 0;
  margin-top: auto;
  margin-bottom: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.message-submit-mini-button-round:hover {
  opacity: 0.9;
}

.message-submit-mini-button-round-content-wrapper {
  margin: auto;
  display: flex;
  flex-direction: row;
}

.message-submit-mini-button-round-text {
  margin: auto;
  font-size: 14px;
  color: var(--UIcolour0);
}
