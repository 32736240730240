/* Original */
/* Mobile - now all */

.preview-button-icon-left-container {
  text-decoration: none;
}

.preview-button-icon-left-solid {
  -webkit-appearance: none;
  margin-right: 0;
  margin-left: auto;
  margin-top: aut;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 138px;
  height: 24px;
  border-radius: var(--UIborderRadius);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-color: var(--brandColour1);
  color: var(--UIcolour0);
  transition: background-color var(--UItransitionTime);
}

.preview-button-icon-left-solid:hover,
.preview-button-icon-left-solid:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.preview-button-icon-left-wrapper-inner-solid {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  background: var(--brandColour1);
}

.preview-button-icon-left-icon-solid {
  height: 14px;
  width: 14px;
  margin: 0px;
  fill: white;
}

@media only screen and (max-width: 800px) {
  .preview-button-icon-left-solid {
    height: 20px;
    width: 84px;
  }
}

/* Old */

.breadcrumb-and-preview-button-wrapper {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.breadcrumb-header-and-preview-button-container {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.breadcrumb-and-header-container {
  margin: 0;
}

.preview-button-icon-left-icon-and-label-wrapper {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
