.button-banner-section-outer-container {
  margin: auto;
}

.button-banner-section-container {
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
}

.button-banner-section-description {
  margin-top: 15px;
  margin-bottom: 15px;
}

.button-banners-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

.button-banner-container {
  width: 31.83%;
  margin-left: 0px;
  margin-right: 1.5%;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-1 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-2 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-3 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-4 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-5 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner-container-6 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.button-banner {
  height: 175px;
  width: 300px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
}

.button-banner:hover,
.button-banner:active {
  background: var(--UIcolour7);
}

.button-banner-label {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 3px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 10px;
  color: white;
  background: var(--brandColour1);
}

.button-banner-callout {
  margin-right: 0px;
  margin-left: auto;
  padding: 5px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 10px;
  color: white;
  background: var(--brandColour1);
}

.button-banner-main-icon-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: 5px;
}

.button-banner-main-icon {
  height: 22px;
  width: 22px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.button-banner-heading-and-chevron-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-banner-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 17px;
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.button-banner-chevron-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 0px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 2px;
}

.button-banner-chevron-icon {
  height: 13px;
  width: 13px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.button-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 15px;
  color: var(--UIcolour2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: auto;
}

@media only screen and (max-width: 1005px) and (min-width: 661px) {
  .button-banner-section-container {
    width: 615px;
    margin: auto;
  }
  .button-banners-container {
    width: 615px;
  }
  .button-banner-container {
    width: 48%;
  }
  .button-banner-container-3 {
    margin-left: 0px;
    margin-right: 0;
  }
  .button-banner-container-4 {
    margin-left: 15px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 660px) {
  .button-banner-section-container {
    width: 100%;
    margin: 0;
  }
  .button-banners-container {
    width: 100%;
    margin: 0;
  }

  .button-banner-container {
    width: 48%;
  }

  .button-banner-container-1 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .button-banner-container-2 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .button-banner-container-3 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .button-banner-container-4 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .button-banner-container-5 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .button-banner-container-6 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .button-banner {
    height: 175px;
    width: 100%;
    max-width: 300px;
  }
  .button-banner-main-icon-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .button-banner-main-icon {
    height: 26px;
    width: 26px;
  }
  .button-banner-heading-and-chevron-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
  }
  .button-banner-heading {
    text-align: center;
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: calc(100vw * 0.33) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .button-banner-chevron-icon {
    display: none;
  }
  .button-banner-subheading {
    display: none;
  }
}
