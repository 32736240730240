.conversation-message-outer-outbound {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  margin-left: 30%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.conversation-message-outer-inbound {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  margin-right: 30%;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.conversation-message-outbound {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: 0px;
  border-radius: 15px 15px 0px 15px;
  background: var(--UIcolour11);
  transition: background var(--UItransitionTime);
  padding: 10px;
}

.conversation-message-inbound {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: auto;
  border-radius: 15px 15px 15px 0px;
  background: var(--UIcolour6);
  transition: background var(--UItransitionTime);
  padding: 10px;
}

.conversation-message-inner {
  margin: auto;
}

.conversation-message-header {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--UIfontType1);
  font-size: 11px;
  color: var(--UIcolour2);
}
.conversation-message-body {
  margin-left: 0px;
  margin-top: 5px;
  text-align: left;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
}

.conversation-message-main-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  max-height: 60px;
  max-width: 60px;
  object-fit: contain;
}

.conversation-message-left-container {
  display: flex;
  margin-left: 5px;
  flex-direction: column;
}

.conversation-message-right-container {
  display: flex;
  margin-right: 5px;
  flex-direction: row;
}

.conversation-message-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour1);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.conversation-message-subheading {
  font-family: var(--UIfontType1);
  font-size: 14px;
  color: var(--UIcolour2);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.conversation-message-date-inbound {
  font-family: var(--UIfontType1);
  font-size: 12px;
  color: var(--UIcolour2);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.conversation-message-date-outbound {
  font-family: var(--UIfontType1);
  font-size: 12px;
  color: var(--UIcolour2);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
}

.conversation-message-select {
  font-size: 14px;
  height: 35px;
  color: var(--UIcolour1);
  border: 1px solid;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 0.5rem;
  font-family: var(--UIfontType1);
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 660px) {
  .conversation-message-subheading {
    text-align: center;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.conversation-message-main-image-wrapper-loading {
  border: none;
  margin-top: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.conversation-message-main-image-loading {
  display: block;
  margin: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  background: #777;
  border: none;
}

.conversation-message-main-image-loading-animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  border: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.conversation-message-file-list-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.conversation-message-file-container {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background: white;
  height: 120px;
  width: 110px;
}

.conversation-message-file-image {
  margin: auto;
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
  border-radius: 15px;
}

.conversation-message-file-icon {
  margin: auto;
  height: 30px;
  width: 30px;
  fill: var(--UIcolour2);
}

.conversation-message-file-name {
  font-family: var(--UIfontType1);
  font-size: 10px;
  color: var(--UIcolour2);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 50px;
  max-width: 90%;
}
