/* Checkbox */
.form-checkbox-and-label-outer-wrapper {
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 100%;
}

/* Customize the label (the container) */
.form-checkbox-and-label-inner-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-family: "SFProText", Sans-Serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  color: var(--UIcolour1);
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.form-checkbox-and-label-inner-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.form-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: solid 1px var(--UIcolour3);
  border-radius: var(--UIborderRadius);
}

/* On mouse-over, add a background color */
.form-checkbox-and-label-inner-wrapper:hover input ~ .form-checkmark {
  border: solid 1px var(--brandColour1);
}

/* When the checkbox is checked, add a background colour */
.form-checkbox-and-label-inner-wrapper input:checked ~ .form-checkmark {
  background-color: white;
  border: solid 1px var(--UIcolour3);
}

/* Create the checkmark/indicator (hidden when not checked) */
.form-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-checkbox-and-label-inner-wrapper input:checked ~ .form-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-checkbox-and-label-inner-wrapper .form-checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid var(--brandColour1);
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
