.radio-button-list-container {
  margin-top: auto;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 0;
  width: 450px;
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .radio-button-list-container {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.radio-button-list-item {
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
}

.radio-button-list-item-text {
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
}
