.infopage-image {
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 15px;
  border-radius: var(--UIborderRadius);
  width: 100%;
  max-width: 300px;
  height: 150px;
  object-fit: cover;
}

@media only screen and (max-width: 450px) {
  .infopage-image {
    max-width: 450px;
    max-height: 200px;
    height: auto;
  }
}

.infopage-subheading {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .infopage-subheading {
    margin-top: 0px;
    font-size: 14px;
  }
}

.infopage-link-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.infopage-text-and-button-wrapper {
  margin: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.infopage-link-box {
  margin-top: 0px;
  padding-top: 15px;
  margin-bottom: 20px;
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
}

.infopage-link-inner-wrapper {
  padding: 15px;
}

.infopage-link {
  line-height: 135%;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  color: var(--brandColour1);
  text-align: center;
}

.infopage-link-button {
  margin-top: 15px;
  margin-right: 0px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--brandColour1);
  border: 1px solid var(--brandColour1);
  border-radius: 0 0 var(--UIborderRadius) var(--UIborderRadius);
}

.infopage-link-button:hover,
.infopage-link-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
  cursor: pointer;
}

.infopage-link-button-nohover {
  margin-top: 15px;
  margin-right: 0px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--brandColour1);
  border: 1px solid var(--brandColour1);
  border-radius: 0 0 var(--UIborderRadius) var(--UIborderRadius);
}

.infopage-label-and-link-wrapper {
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.infopage-link-label {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  color: white;
  text-align: center;
  text-decoration: none;
}

.infopage-qrcode-wrapper {
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.infopage-qrcode-image {
  width: 100%;
  max-width: 80px;
  max-height: 80px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.infopage-link-box-icon {
  height: 16px;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  fill: white;
}

.infopage-link-inline-icon {
  height: 14px;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  fill: var(--brandColour1);
}

.infopage-link-mini-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  font-size: 11px;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 4px;
  padding: 2px 4px;
}

.infopage-link-mini-button:hover,
.infopage-link-mini-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
  cursor: pointer;
}

.infopage-link-mini-button-inverted {
  margin-top: auto;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 11px;
  color: var(--brandColour1);
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--brandColour1);
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}

.infopage-link-mini-button-inverted-red {
  margin: auto;
  font-size: 11px;
  color: var(--UIcolour5);
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--UIcolour5);
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}

.passcode-input-form-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.passcode-input-form {
  margin: auto;
}

.passcode-input-form-label {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--UIfontType1);
  font-size: 16px;
  line-height: 130%;
  color: var(--UIcolour1);
}

.passcode-text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 138px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 40px;
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.passcode-text-input:focus {
  border-color: var(--brandColour1);
}

.passcode-text-input::placeholder {
  color: var(--UIcolour3);
}

.passcode-text-error-message {
  margin-top: 10px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: var(--UIcolour5);
  line-height: 130%;
}

.passcode-save-button {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 150px;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.passcode-save-button:hover,
.passcode-save-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}
