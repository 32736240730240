.popup-content {
  overflow-x: auto !important;
  overflow-y: auto !important;
  margin: auto;
  background-color: white;
  border: none;
  border-radius: var(--UIborderRadius);
  animation: globalFadeIn ease var(--UItransitionTime);
  -webkit-animation: globalFadeIn ease var(--UItransitionTime);
  -moz-animation: globalFadeIn ease var(--UItransitionTime);
  -o-animation: globalFadeIn ease var(--UItransitionTime);
  -ms-animation: globalFadeIn ease var(--UItransitionTime);
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.infoitemmulti-popup-modal-open-button-round {
  background-color: var(--brandColour1);
  height: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid var(--brandColour1);
  border-radius: 50px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoitemmulti-popup-modal-open-button-round:hover {
  border: 1px solid var(--brandColour1Dulled);
  background-color: var(--brandColour1Dulled);
}

.infoitemmulti-popup-modal-open-button-round-text {
  margin-top: auto;
  margin-bottom: auto;
  margin: auto;
  padding: 0;
  font-size: 10px;
  color: var(--UIcolour0);
  line-height: 100%;
}

.infoitemmulti-popup-modal-open-button-round-text:hover {
  color: var(--UIcolour0);
}

.infoitemmulti-popup-modal-box {
  margin: auto;
}

@media screen and (max-width: 600px) {
  .infoitemmulti-popup-modal-box {
    width: 100%;
    height: 100%;
  }
  .popup-content {
    max-height: 100%;
  }
}

@media screen and (min-width: 601px) {
  .infoitemmulti-popup-modal-box {
    width: 100%;
    max-width: calc(100vw - 5em);
    height: 100%;
  }
  .popup-content {
    max-height: calc(100vh - 5em);
  }
}

.infoitemmulti-popup-modal-close-button-wrapper {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: 5px;
  margin-right: 5px;
}

.infoitemmulti-popup-modal-close-button {
  background-color: var(--UIcolour0);
  border-radius: 50%;
  border: 1px solid var(--theme-color-primary);
  height: 30px;
  width: 30px;
  margin-top: 0px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 0px;
  cursor: pointer;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.infoitemmulti-popup-modal-close-button-icon-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.infoitemmulti-popup-modal-close-button-icon {
  background-color: transparent;
  height: 16px;
  width: 16px;
  margin: auto;
  padding: 0;
  fill: var(--theme-color-primary);
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.infoitemmulti-popup-modal-content-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: -15px;
  margin-right: 30px;
  margin-left: 30px;
}

.mini-pop-up-container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: auto;
  padding-right: auto;
}

@media screen and (max-width: 600px) {
  .mini-pop-up-container-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

@media screen and (min-width: 601px) {
  .mini-pop-up-container-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
}

.mini-pop-up-container-text {
  margin-top: 30px;
  padding: 0px;
}
