.notification-banner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  /* position: sticky; */
  /* top: 62px; */
}

.notification-banner {
  height: auto;
  width: 100%;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: left;
  border-left: 5px solid var(--brandColour3);
  background: var(--UIcolour6);
  transition: filter var(--UItransitionTime);
}

.notification-banner-compact {
  height: 62px;
  width: 100%;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: left;
  border-left: 5px solid var(--brandColour3);
  background: var(--UIcolour6);
  transition: filter var(--UItransitionTime);
}

.notification-banner-clickable {
  height: auto;
  width: 100%;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: left;
  border-left: 5px solid var(--brandColour3);
  cursor: pointer;
  background: var(--UIcolour6);
  transition: filter var(--UItransitionTime);
}

@media only screen and (min-width: 451px) {
  .notification-banner-clickable {
    flex-direction: row;
    justify-content: start;
    flex-wrap: nowrap;
    padding: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .notification-banner-clickable {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}

.notification-banner-clickable:hover,
.notification-banner-clickable:active {
  background: var(--UIcolour7);
}

.notification-banner-clickable-compact {
  height: 62px;
  width: 100%;
  margin-right: 0px;
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: left;
  border-left: 5px solid var(--brandColour3);
  cursor: pointer;
  background: var(--UIcolour6);
  transition: filter var(--UItransitionTime);
}

@media only screen and (min-width: 451px) {
  .notification-banner-clickable-compact {
    flex-direction: row;
    justify-content: start;
    flex-wrap: nowrap;
    padding: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .notification-banner-clickable-compact {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}

.notification-banner-clickable-compact:hover,
.notification-banner-clickable-compact:active {
  background: var(--UIcolour7);
}

.notification-banner-main-image {
  border-radius: var(--UIborderRadius);
  width: 100%;
  height: auto;
}

.notification-icon-and-text-wrapper {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 15px;
}

.notification-icon-wrapper {
  margin: auto;
  padding-top: 3px;
}

.notification-icon {
  fill: var(--brandColour3);
}

.notification-banner-text {
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--brandColour3);
}

@media only screen and (min-width: 451px) {
  .notification-banner-text {
    margin: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .notification-banner-text {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.notification-banner-button-container {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 451px) {
  .notification-banner-button-container {
    margin: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .notification-banner-button-container {
    margin-bottom: 20px;
  }
}

.notification-banner-button {
  margin-left: auto;
  margin-right: auto;
  width: 170px;
  height: 42px;
  background-color: var(--brandColour3);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--UIcolour0);
  font-size: 16px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}
