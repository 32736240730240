.icon-and-text-inline-container {
  background-color: transparent;
  height: auto;
  padding-left: 0px;
  padding-right: 15px;
  border: none;
  border-radius: 15px;
  margin-left: 0;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon-and-text-inline-icon {
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 0;
  margin-left: -2px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.icon-and-text-inline-text {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  font-size: 14px;
  color: var(--UIcolour1);
  margin-left: 10px;
  margin-right: auto;
  line-height: 130%;
}

.countdown-text {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour5);
  line-height: 130%;
}

.countdown-text-home {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 30px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour5);
  line-height: 130%;
}

.subscription-product-container {
  height: auto;
  width: auto;
  max-width: 525px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.subscription-product-text-and-currency-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subscription-product-text {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-left: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
}

.currency-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 53px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 5px;
  height: 30px;
  font-size: 12px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.currency-selector:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.payment-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-right: auto;
  align-items: center;
  margin-top: 45px;
}

.stripe-badge {
  width: auto;
  height: 40px;
  margin-right: 10px;
  opacity: 0.6;
}

.card-badge-container {
  width: auto;
  height: 20px;
  margin-left: 0px;
}

.card-logo {
  width: auto;
  height: 20px;
  margin-left: -5px;
}

.cancel-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 15px;
  width: auto;
  min-width: 220px;
  height: 50px;
  background-color: var(--UIcolour5);
  border: 1px solid var(--UIcolour5);
  border-radius: var(--UIborderRadius);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
  transition: color var(--UItransitionTime);
  border-color: color var(--UItransitionTime);
}

.cancel-button:hover,
.cancel-button:focus {
  background-color: rgba(224, 0, 0, 0.788);
  color: white;
  outline: 0;
}

.cancel-button:disabled {
  opacity: 0.6;
}

.cancel-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: white;
  border: 1px solid var(--UIcolour5);
  border-radius: var(--UIborderRadius);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--UIcolour5);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
  transition: color var(--UItransitionTime);
}

.cancel-button-inverted:hover,
.cancel-button-inverted:focus {
  background-color: var(--UIcolour5);
  color: white;
  outline: 0;
}

.cancel-button-inverted:disabled {
  opacity: 0.6;
}

.cancel-options-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-top: 15px;
}

.keep-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.keep-button-inverted:hover,
.keep-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.keep-button-inverted:disabled {
  opacity: 0.6;
}

.subscription-radio-buttons-container {
  border-bottom: 1px solid var(--UIcolour3);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.subscription-radio-button-container {
  display: flex;
  flex-direction: row;
  align-items: middle;
  margin-left: 0px;
  margin-bottom: 7px;
  cursor: pointer;
}

.subscription-radio-button-text {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  color: var(--UIcolour1);
  margin-left: 10px;
  margin-right: auto;
  cursor: pointer;
}

.subscription-radio-button-text-saving {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  margin-left: 10px;
  margin-right: auto;
  background: var(--UIcolour9);
  color: var(--UIcolour0);
  border-radius: var(--UIborderRadius);
}

.subscription-radio-button-container input[type="radio"] {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 0px;
  cursor: pointer;
}
