.homepage-canvas {
  max-width: var(--globalPageWidth);
  background-color: var(--UIcolour0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.login-form-wrapper {
  margin: auto;
  animation: globalFadeIn ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.homepage-logo-wrapper {
  width: 35%;
  min-width: 250px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.homepage-heading {
  margin: auto;
  width: 35%;
  min-width: 250px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 22px;
}

.login-form {
  width: 32%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.submission-form-label {
  font-size: 20px;
}

.submission-form-input-and-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 40px;
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.login-text-input:focus {
  border-color: var(--brandColour1);
}

.login-text-input::placeholder {
  color: var(--UIcolour3);
}

.login-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.login-button:hover,
.login-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.login-button:disabled {
  opacity: 0.6;
}

.inline-edit-link {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.inline-edit-link:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.inline-edit-link:focus {
  outline: 0;
  opacity: 0.75;
}

.inline-edit-link-grey {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour2);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.inline-edit-link-grey:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.inline-edit-link-grey:focus {
  outline: 0;
  opacity: 0.75;
}

.inline-static-link-grey {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour2);
  background: transparent;
  font-size: 15px;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.inline-error-link {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour5);
  background: transparent;
  font-size: 14px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
  text-decoration: underline;
}

.inline-error-link:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.inline-error-link:focus {
  outline: 0;
  opacity: 0.75;
}
