.radio-image-banner {
  height: 110px;
  width: 100%;
  margin-right: 0px;
  max-width: 360px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: middle;
  cursor: pointer;
  transition: background var(--UItransitionTime);
}

.radio-image-banner:hover,
.radio-image-banner:active {
  background: var(--UIcolour7);
}

.radio-image-banner:hover ~ .radio-image-banner-subheading {
  color: var(--brandColour1);
}

.radio-image-banner-main-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  margin-left: 0;
}

.radio-image-banner-radio-and-heading-container {
  margin: auto;
  cursor: pointer;
}

.radio-image-banner-radio-and-heading-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  padding: 5px;
  width: auto;
}

.radio-image-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
  margin-left: 10px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.radio-image-banner-input {
  margin-right: 0;
  margin-left: 5px;
  cursor: pointer;
}

.radio-image-banners-container {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
}

.radio-image-banner-container {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  margin-bottom: 15px;
}

@media only screen and (max-width: 660px) {
  .radio-image-banners-container {
    width: 100%;
    margin: 0;
    margin-right: -15px;
  }

  .radio-image-banner-container {
    margin-right: 0px;
    width: 100%;
  }
}

.radios-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.radio-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}

.radio-input {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.radio-image {
  height: 100%;
  max-height: 60px;
  max-width: 90px;
  object-fit: cover;
  cursor: pointer;
}

.radio-text {
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
  margin-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.radio-input-container {
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.radio-image-banner-radio-and-heading-inner-container input[type="radio"] {
  vertical-align: middle;
}

.edit-button-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 10px;
}
