.infoitem-checkbox-listitems-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  margin-left: 0;
  width: 450px;
  font-size: 16px;
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .infoitem-checkbox-listitems-container {
    width: 100%;
    margin: 0;
  }
}

.infoitem-checkbox-listitem {
  margin-left: 0;
  margin: auto;
  font-size: 16px;
}

.infoitem-listitem-checkbox-and-label-inner-wrapper {
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
}
