/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  -webkit-appearance: none;
  margin: 0;
  height: auto;
  padding: 10px 12px;
  width: 100%;
  background-color: white;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  height: auto;
  font-family: var(--UIfontType1);
  font-size: 16px;
  transition: border-color var(--UItransitionTime);
}

.StripeElement--focus {
  border-color: var(--brandColour1);
}

.StripeElement--invalid {
  border-color: var(--UIcolour5);
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
