.conversation-response {
  background: var(--UIcolour6);
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  border-top: 1px solid var(--UIcolour3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
}

.conversation-response-outer-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 13.5px;
  margin-bottom: 13.5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.conversation-response-inner-container {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.conversation-response-inner-top-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: bottom;
  width: 100%;
  max-width: 500px;
}

.conversation-response-inner-bottom-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.conversation-response-file-list-container {
  margin-top: 5px;
}

.conversation-response-textarea-container {
  padding: auto;
  margin-left: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conversation-response-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;
  margin-left: 0px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border: solid 0.5px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.conversation-response-textarea::placeholder {
  color: var(--UIcolour3);
}

.conversation-response-textarea:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: auto;
  outline: none;
}

.conversation-response-avatar-image {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px;
  margin-right: 5px;
  object-fit: cover;
  border: 1px solid var(--UIcolour3);
}
