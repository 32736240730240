.avatar-banner {
  height: 200px;
  width: 300px;
  position: relative;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
}

.avatar-banner:hover,
.avatar-banner:active {
  background: var(--UIcolour7);
}

.avatar-banner-main-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  object-fit: cover;
}

.avatar-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 15px;
  color: var(--UIcolour2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 660px) {
  .avatar-banner {
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  .avatar-banner-subheading {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: auto;
  }
  .avatar-banner-main-image {
    margin-left: auto;
    margin-right: auto;
  }
}
