.section-details-page-container {
  margin-top: 30px;
}

.report-checkitem-outer-wrapper {
  margin-left: 0;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: auto;
}

.report-checkitem-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 15px;
}

.report-checkitem-icon-wrapper-checked {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.report-checkitem-icon-checked {
  height: 12px;
  width: 12px;
  margin: 4px;
  fill: white;
}

.report-checkitem-icon-wrapper-unchecked {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: gray;
}

.report-checkitem-icon-unchecked {
  height: 10px;
  width: 10px;
  margin: 5px;
  fill: white;
}

.report-checkitem-icon-wrapper-na {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.report-checkitem-icon-na {
  height: 10px;
  width: 10px;
  margin: 5px;
  fill: white;
}

.report-checkitem-description-wrapper {
  margin: auto;
  margin-left: 0;
  margin-bottom: 0;
}

.report-checkitem-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0;
}

.report-checkitem-description {
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-left: 28px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0;
}

.report-checkitem-description-ul {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: auto;
  padding: 0;
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 125%;
  text-align: left;
}

.checkitem-edit-button {
  -webkit-appearance: none;
  margin-left: 28px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color var(--UItransitionTime);
  text-decoration: underline;
}

.checkitem-edit-button:hover {
  text-decoration: underline;
}

.checkitem-edit-button:focus {
  outline: 0;
}
