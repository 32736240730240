.cancellation-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 400px;
}

.cancellation-form-textarea {
  width: 100%;
  max-width: 376px;
  height: 60px;
  margin-left: 0px;
  margin-right: 45px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 10px;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.cancellation-form-textarea:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.cancellation-form-textarea::placeholder {
  color: var(--UIcolour3);
}

.cancellation-form-select {
  width: 100%;
  color: var(--UIcolour2);
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 5px;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.cancellation-form-select::placeholder {
  color: var(--UIcolour3);
}
