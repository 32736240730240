.show {
  margin: auto;
  animation: globalFadeIn ease 0.05s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.hide {
  display: none;
}

.infoitem-container {
  margin: auto;
  margin-left: 0;
  max-width: 555px;
}

.infoitem-container-box {
  margin: auto;
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 540px;
  background: var(--UIcolour10);
  border: 1px solid var(--UIcolour7);
  border-radius: var(--UIborderRadius);
  padding: 15px;
}

.infoitem-container-box-heading {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour2);
}

.infoitem-heading-and-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoitem-heading {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  display: flex;
  flex-direction: row;
}

.infoitem-edit-button {
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color underline var(--UItransitionTime);
}

.infoitem-edit-button:hover {
  text-decoration: underline;
}

.infoitem-edit-button:focus {
  outline: 0;
}

.infoitem-edit-button-loading {
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour2);
  background: transparent;
  font-size: 16px;
  cursor: none;
}

.infoitem-edit-button-red {
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--UIcolour5);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color underline var(--UItransitionTime);
}

.infoitem-edit-button-red:hover {
  text-decoration: underline;
}

.infoitem-edit-button-red:focus {
  outline: 0;
}

.infoitem-inline-edit-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.infoitem-inline-edit-button:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.infoitem-inline-edit-button:focus {
  outline: 0;
  opacity: 0.75;
}

.infoitem-span-edit-button {
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.infoitem-span-edit-button:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.infoitem-span-edit-button:focus {
  outline: 0;
  opacity: 0.75;
}

.infoitem-value {
  margin-left: 0;
  margin-right: 5px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
  line-height: 130%;
}

.infoitem-value-list-heading {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: var(--UIcolour1);
}

.infoitem-value-list {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
}

.infoitem-value-list-item {
  margin-top: 0px;
  margin-bottom: 5px;
}

.infoitem-attachments-outer-container {
  margin-top: 8px;
  margin-bottom: 12px;
}

.infoitem-attachments-container {
  margin: auto;
  margin-left: 0;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.infoitem-attachments-banner-container {
  margin-top: 0;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 0px;
  display: flex;
}

.infoitem-preview-container {
  margin-left: 0px;
  margin-top: 0;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.infoitem-preview-container-inner {
  margin-left: 0;
  margin-top: 8px;
  border-radius: var(--UIborderRadius);
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoitem-preview-video {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: black;
  object-fit: contain;
  border: none;
  border-radius: var(--UIborderRadius);
  height: calc(var(--width) / 1.5);
  width: auto !important;
  height: auto !important;
  max-width: 150px;
  max-height: 150px;
}

.infoitem-image-container {
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: start;
}

.infoitem-image {
  margin-left: 0;
  margin-top: 0;
  border-radius: var(--UIborderRadius);
  width: auto;
  max-height: 150px;
  max-width: 300px;
  object-fit: contain;
}

.infoitem-image-circle {
  margin-left: 0;
  margin-top: 0;
  border-radius: 50%;
  width: auto;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.infoitem-image-rectangle {
  margin-left: 0;
  margin-top: 0;
  border-radius: var(--UIborderRadius);
  width: auto;
  height: 100px;
  width: auto;
  object-fit: contain;
}

.infoitem-image-preview-container {
  margin-left: 0;
  margin-top: 8px;
  border-radius: var(--UIborderRadius);
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoitem-image-preview-container-loading {
  margin-left: 0;
  margin-top: 8px;
  border: none !important;
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.infoitem-image-preview {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  object-fit: contain;
  border: none;
  border-radius: var(--UIborderRadius);
  height: calc(var(--width) / 1.5);
  width: auto !important;
  height: auto !important;
  max-width: 150px;
  max-height: 150px;
}

.infoitem-image-preview-loading {
  display: block;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  object-fit: contain;
  border: none !important;
  border-radius: var(--UIborderRadius);
  width: 150px;
  height: 150px;
}

.infoitem-image-preview-loading-animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  border: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.infoitem-image-input {
  display: none;
}

.custom-file-upload {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: 8px;
  padding: auto;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  border: 1px;
  border-style: dashed;
  border-color: var(--UIcolour3);
  height: 150px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.custom-file-upload:hover {
  background: var(--UIcolour7);
}

.custom-file-upload:hover .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.custom-file-upload:hover .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.custom-file-upload:focus {
  background: var(--UIcolour7);
}

.custom-file-upload:focus .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.custom-file-upload:focus .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.custom-file-upload-inner-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-file-upload-icon {
  margin: auto;
  height: 30px;
  width: 30px;
  fill: var(--brandColour1);
}
.custom-file-upload-text {
  margin-top: 10px;
  margin-bottom: auto;
  color: var(--brandColour1);
}

.infoitem-file-delete-button {
  z-index: 5;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
  background: white;
  cursor: pointer;
}
.infoitem-file-delete-icon {
  margin: auto;
  height: 20px;
  width: 20px;
  fill: var(--UIcolour2);
}

.infoitem-file-delete-button:hover .infoitem-file-delete-icon {
  fill: var(--brandColour1Dulled);
}

.infoitem-file-delete-button:focus {
  background: var(--UIcolour7);
  outline: 0;
}

.infoitem-file-delete-button:focus .infoitem-file-delete-icon {
  fill: var(--brandColour1Dulled);
}

.infoitem-form-container {
  margin-left: 0;
}

.infoitem-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
}

.infoitem-form-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
}

.infoitem-form-inner-container-left {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-buttons {
  margin: auto;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoitem-form-inner-container-map {
  margin: auto;
  margin-right: auto;
  margin-left: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-full {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-textarea {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.infoitem-list-container {
  margin: auto;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 15px;
  width: 100%;
  max-width: 555px;
  display: flex;
  flex-direction: column;
}

.infoitem-list-container ol {
  list-style-type: none;
  margin-left: 0;
  padding: 0;
}

.infoitem-listitem {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  max-width: 555px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: var(--UIborderRadius);
  transition: background var(--UItransitionTime);
}

.infoitem-listitem:hover {
  cursor: move;
  background: var(--UIcolour6);
}

.infoitem-listitem-static {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  max-width: 555px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: var(--UIborderRadius);
}

.infoitem-listitem-image-and-detail-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  margin-right: 0;
}

.infoitem-listitem-number {
  font-size: 15px;
  width: 35px;
  margin-right: 8px;
  color: var(--UIcolour1);
}

.infoitem-listitem-detail-container {
  margin-top: 0;
  margin-right: 5px;
  width: 100%;
}

@media only screen and (min-width: 570px) {
  .infoitem-listitem-text-container {
    max-width: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (min-width: 450px) and (max-width: 569px) {
  .infoitem-listitem-text-container {
    max-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (min-width: 450px) {
  .infoitem-listitem-text-and-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .infoitem-listitem-heading {
    margin-left: 0;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 8px;
    font-family: var(--UIfontType1SemiBold);
    font-size: 16px;
    font-family: var(--UIfontType1SemiBold);
    font-size: 15px;
    overflow-wrap: break-word !important;
    max-width: 300px !important;
  }

  .infoitem-listitem-location-wrapper {
    background-color: transparent;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-radius: 15px;
    margin-left: 0;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow-wrap: break-word !important;
    max-width: 300px !important;
  }

  .infoitem-listitem-location-text-wtw {
    font-family: var(--UIfontType1);
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    margin-left: 0px;
    margin-right: auto;
    font-size: 14px;
    color: var(--UIcolour2);
    line-height: 125%;
    text-align: left;
    margin-bottom: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 449px) {
  .infoitem-listitem-text-and-button-container {
    display: flex;
    flex-direction: column;
  }

  .infoitem-listitem-text-container {
    max-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-right: 0;
  }

  .infoitem-listitem-heading {
    margin-left: 0;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 8px;
    font-family: var(--UIfontType1SemiBold);
    font-size: 15px;
    max-width: 130px !important;
    overflow-wrap: break-word !important;
  }

  .infoitem-listitem-location-wrapper {
    background-color: transparent;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-radius: 15px;
    margin-left: 0;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    max-width: 130px !important;
    overflow-wrap: break-word !important;
  }

  .infoitem-listitem-location-text-wtw {
    display: none;
  }
}

.infoitem-listitem-location-icon {
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 0;
  margin-left: -2px;
  margin-top: 0;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.infoitem-listitem-location-icon-warning {
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 0;
  margin-left: -2px;
  margin-top: 0;
  margin-bottom: auto;
  fill: var(--UIcolour5);
}

.infoitem-listitem-location-text {
  font-family: var(--UIfontType1);
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  margin-left: 5px;
  margin-right: auto;
  font-size: 14px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-listitem-location-text-warning {
  font-family: var(--UIfontType1);
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  margin-left: 5px;
  margin-right: auto;
  font-size: 14px;
  color: var(--UIcolour5);
  line-height: 125%;
  text-align: left;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-listitem-text {
  font-size: 15px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-listitem-edit-button {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  opacity: 1;
  transition: color opacity var(--UItransitionTime);
  text-decoration: underline;
  text-align: left;
}

.infoitem-listitem-edit-button:hover {
  opacity: 0.75;
}

.infoitem-listitem-edit-button:focus {
  opacity: 0.75;
  outline: 0;
}

.infoitem-listitem-checkbox-and-label-outer-wrapper {
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 100%;
}

/* Customize the label (the container) */
.infoitem-listitem-checkbox-and-label-inner-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-family: "SFProText", Sans-Serif;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 130%;
  color: var(--UIcolour1);
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.infoitem-listitem-checkbox-and-label-inner-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.infoitem-listitem-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: solid 1px var(--UIcolour3);
  border-radius: var(--UIborderRadius);
}

/* On mouse-over, add a background color */
.infoitem-listitem-checkbox-and-label-inner-wrapper:hover
  input
  ~ .form-checkmark {
  border: solid 1px var(--brandColour1);
}

/* When the checkbox is checked, add a background colour */
.infoitem-listitem-checkbox-and-label-inner-wrapper
  input:checked
  ~ .form-checkmark {
  background-color: white;
  border: solid 1px var(--UIcolour3);
}

/* Create the checkmark/indicator (hidden when not checked) */
.infoitem-listitem-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.infoitem-listitem-checkbox-and-label-inner-wrapper
  input:checked
  ~ .form-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.infoitem-listitem-checkbox-and-label-inner-wrapper .form-checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid var(--brandColour1);
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.infoitem-listitem-textarea-and-button-container {
  display: flex;
  flex-direction: column;
  width: auto;
}

.infoitem-listitem-textarea {
  width: auto;
  height: 120px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 10px;
  font-size: 15px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.infoitem-listitem-textarea:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.infoitem-listitem-textarea::placeholder {
  color: var(--UIcolour3);
}

.infoitem-listitem-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: 80px;
  height: auto;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--UIcolour0);
  font-size: 13px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.infoitem-listitem-button:hover,
.infoitem-listitem-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.infoitem-listitem-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  background-color: var(--UIcolour0);
  border: 1px solid;
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--brandColour1);
  font-size: 13px;
  cursor: pointer;
  transition: border var(--UItransitionTime);
  transition: color var(--UItransitionTime);
  transition: background-color var(--UItransitionTime);
}

.infoitem-listitem-button-inverted:hover,
.infoitem-listitem-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  border: none;
  outline: 0;
}

.infoitem-listitem-button-danger {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 60px;
  height: 30px;
  background-color: var(--UIcolour5);
  border: none;
  border-radius: var(--UIborderRadius);
  color: var(--UIcolour0);
  font-size: 13px;
  cursor: pointer;
  opacity: 1;
  transition: opacity var(--UItransitionTime);
}

.infoitem-listitem-button-danger:hover,
.infoitem-listitem-button-danger:focus {
  opacity: 0.75;
  outline: 0;
}

.infoitem-listitem-drag-line {
  border-color: var(--brandColour1) !important;
  border-style: solid !important;
  border-width: 0.5px !important;
}

.infoitem-card-section-container {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

.infoitem-card-section-container-inner {
  width: auto;
  margin-left: 0px;
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.infoitem-form-inner-container-right {
  margin: auto;
  margin-right: 0px;
  margin-left: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-label {
  font-size: 16px;
  line-height: 130%;
  color: var(--UIcolour1);
}

.infoitem-form-label-map {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--UIcolour1);
}

.infoitem-text-preview {
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-text-input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  outline: none !important;
  appearance: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
  width: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 40px;
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.infoitem-text-input:focus {
  border-color: var(--brandColour1);
}

.infoitem-text-input::placeholder {
  color: var(--UIcolour3);
}

.infoitem-textarea {
  width: auto;
  height: 120px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 10px;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.infoitem-textarea:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.infoitem-textarea::placeholder {
  color: var(--UIcolour3);
}

.infoitem-save-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 150px;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.infoitem-save-button:hover,
.infoitem-save-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.infoitem-save-button:disabled {
  opacity: 0.6;
}

.infoitem-save-button-container-padding-left {
  padding-left: 28px;
}

.infoitem-delete-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 140px;
  height: 30px;
  background-color: var(--UIcolour0);
  border: solid 1px var(--UIcolour5);
  border-radius: var(--UIborderRadius);
  padding: auto;
  color: var(--UIcolour5);
  font-size: 15px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
  transition: color var(--UItransitionTime);
}

.infoitem-delete-button:hover,
.infoitem-delete-button:focus {
  background-color: var(--UIcolour5);
  color: var(--UIcolour0);
  outline: 0;
}

.infoitem-delete-button:disabled {
  opacity: 0.6;
}

.infoitem-delete-button-container-padding-left {
  padding-left: 28px;
}

.infoitem-form-error-message {
  margin-top: 10px;
  font-size: 14px;
  max-width: 60%;
  margin-left: 0;
  margin-right: auto;
  color: var(--UIcolour5);
  line-height: 130%;
}

.infoitem-form-error-message-left-align {
  margin-top: 10px;
  font-size: 14px;
  max-width: 100%;
  margin-left: 0px;
  margin-right: auto;
  color: var(--UIcolour5);
  line-height: 130%;
}

.infoitem-section-break-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

.infoitem-section-break {
  border-top: 1px solid #cccccc;
  height: auto;
  max-width: 960px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.delete-button-inverted {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 150px;
  height: 50px;
  background-color: white;
  border: 1px solid var(--UIcolour5);
  border-radius: var(--UIborderRadius);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--UIcolour5);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
  transition: color var(--UItransitionTime);
}

.delete-button-inverted:hover,
.delete-button-inverted:focus {
  background-color: var(--UIcolour5);
  color: white;
  outline: 0;
}

.delete-button-inverted:disabled {
  opacity: 0.6;
}

.local-profiles-count-text {
  font-family: var(--UIfontType1);
  font-size: 14px;
  color: var(--UIcolour2);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

/* Google Autocomplete search input */

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: var(--UIborderRadius);
  border-top: 1px solid var(--UIcolour3);
  border-left: 1px solid var(--UIcolour3);
  border-right: 1px solid var(--UIcolour3);
  border-bottom: 1px solid var(--UIcolour3);
  font-family: "SF Pro Text", Sans-Serif;
  box-shadow: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}

.pac-icon {
  display: none;
}

.pac-item-query {
  font-family: "SF Pro Text", Sans-Serif;
  padding-left: 7px;
  font-size: 16px;
  color: var(--UIcolour1);
}

.pac-item {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 250%;
  margin-top: auto;
  margin-bottom: auto;
}

.pac-item:hover {
  background: var(--brandColour2);
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .infoitem-form-inner-container-left {
    width: 100%;
    margin: 0;
  }
  .infoitem-form-inner-container-right {
    width: 100%;
    margin: 0;
  }
  .infoitem-form-inner-container-full {
    width: 100%;
    margin: 0;
  }
}
