.image-banner {
  height: 250px;
  width: 300px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
}

.image-banner:hover,
.image-banner:active {
  background: var(--UIcolour7);
}

.image-banner-main-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.image-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 15px;
  color: var(--UIcolour2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: auto;
}

@media only screen and (max-width: 660px) {
  .image-banner-subheading {
    text-align: center;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.image-banner-main-image-wrapper-loading {
  border: none;
  margin-top: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.image-banner-main-image-loading {
  display: block;
  margin: 0;
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  background: #777;
  border: none;
}

.image-banner-main-image-loading-animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  border: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
