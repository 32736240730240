@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Regular.eot");
  src: local("SF Pro Text"), local("SFProText"),
    url("./fonts/SF-Pro-Text-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SF-Pro-Text-Regular.otf") format("opentype"),
    url("./fonts/SF-Pro-Text-Regular.woff2") format("woff2"),
    url("./fonts/SF-Pro-Text-Regular.woff") format("woff"),
    url("./fonts/SF-Pro-Text-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Bold";
  src: url("./fonts/SF-Pro-Text-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/SF-Pro-Text-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SF-Pro-Text-Bold.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/SF-Pro-Text-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/SF-Pro-Text-Bold.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/SF-Pro-Text-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Semibold";
  src: url("./fonts/SF-Pro-Text-Semibold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/SF-Pro-Text-Semibold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SF-Pro-Text-Semibold.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/SF-Pro-Text-Semibold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/SF-Pro-Text-Semibold.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/SF-Pro-Text-Semibold.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

* {
  --globalPageWidth: 960px;
  --globalContentWidth: 900px;
  --responsiveBreakpoint: 600px;
  --mobileBreakpoint: 450px;
  --headerHeight: 62px;
  --headerNavHeight: 130px;
  --compactHeaderHeight: 44px;
  --footerHeight: 64px;
  --UIcolour0: #ffffff;
  --UIcolour1: #333333;
  --UIcolour2: #6a6a6a;
  --UIcolour3: #cccccc;
  --UIcolour4: #cccccc94;
  --UIcolour5: #ed495a;
  --UIcolour6: #f0f0f0;
  --UIcolour7: #ededed;
  --UIcolour8: #10b9e8;
  --UIcolour9: #ff7f50;
  --UIcolour10: #f7f7f7;
  --UIcolour11: #e5f3f7;
  --UIfontType1: "SF Pro Text", sans-serif; /* ""Inter Variable", sans-serif; */
  --UIfontType1SemiBold: "SFProText-Semibold", sans-serif; /* ""Inter Variable", sans-serif; */
  --UIborderRadius: 4px;
  --UIimageFadeInTime: 0s;
  --UItransitionTime: 0.15s;
  --brandColour1: #1ec1a1;
  --brandColour1Dulled: #22ba9d;
  --brandColourOpaque: rgba(30, 194, 161, 0.15);
  --brandColour2: #e5f3f7;
  --brandColour3: #1e8fc1;
  --bodyOverflow: visible;
  --globalFadeEase: 0.1s;
}

* {
  font-family: var(--UIfontType1), Sans-Serif;
}

html,
body,
#App {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow: var(--bodyOverflow);
}

#tidio-chat {
  display: var(--tidio-display);
}

input:focus {
  outline: 0;
}

h1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-weight: 600;
  font-size: 30px;
  color: var(--UIcolour1);
  transition: 0.4s;
}

@media only screen and (max-width: 600px) {
  h1 {
    letter-spacing: -0.5px;
  }
}

h2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 22px;
  color: var(--UIcolour2);
}

h3 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-weight: 600;
  font-size: 20px;
  color: var(--UIcolour1);
}

@media only screen and (max-width: 600px) {
  h3 {
    letter-spacing: -0.5px;
    font-size: 18px;
  }
}

p {
  font-weight: 400;
  color: var(--UIcolour2);
  font-size: 18px;
  line-height: 150%;
  text-align: left;
  margin-left: 0px;
  margin-right: 15px;
}

p a:link {
  color: var(--UIcolour2);
  text-decoration: underline;
  text-decoration-color: var(--UIcolour2);
}

p a:hover {
  color: var(--UIcolour1);
  text-decoration: underline;
  text-decoration-color: var(--UIcolour1);
}

p a:active {
  color: var(--UIcolour1);
  text-decoration: underline;
  text-decoration-color: var(--UIcolour1);
}

p a:visited {
  color: var(--UIcolour2);
  text-decoration: underline;
  text-decoration-color: var(--UIcolour2);
}

/* Global Fade In */

@keyframes globalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes globalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes globalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes globalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes globalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Global Fade Out */

@keyframes globalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes globalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes globalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes globalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes globalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
