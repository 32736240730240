.search-bar-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 15px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 40px;
  height: 40px;
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.search-bar-input:focus {
  border-color: var(--brandColour1);
}

.search-bar-input::placeholder {
  color: var(--UIcolour3);
}
